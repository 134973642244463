import queryString from 'query-string'
import React, { useContext, useEffect, useState } from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import ShopExplore from '../components/shop-explore'

const ShopPage = ({ data, location }) => {
  const [searchParams, setSearchParams] = useState()
  const [phoneCovers, setPhoneCovers] = useState(false)
  useEffect(() => {
    const search = queryString.parse(location.search)
    const terms = ['travel-bags', 'luggage', 'accessories', 'sets']
    const paramKeys = Object.keys(search)
    paramKeys.map((r) => {
      const index = terms.findIndex((val) => r === val)
      if (index !== -1) {
        setSearchParams(index)
      }
    })
    if (paramKeys.includes('phone-covers')) {
      setPhoneCovers(true)
    }
  }, [location.search])

  return (
    <Layout location="shop">
      <SEO title={`Shop July Luggage & Travel Bags Online`} />
      <ShopExplore phoneCovers={phoneCovers} searchParams={searchParams} />
    </Layout>
  )
}

export default ShopPage
